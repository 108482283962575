
window.CartControls = class CartControls{

  constructor(options){
    this.options = options;
  }

  init(container){
    this.attachQuantityChangeListeners();
    this.attachQuantityClickListener();
    this.attachQuantityControlListeners();
    this.attachRemoveItemClickListener();
    this.timeoutIds = {};
    this.container = container;
  }

  attachQuantityChangeListeners(){

    this.getQuantitySelector().off("keyup").on("keyup", (e)=>{
      let quantity = $(e.target).val();
      if(!/^\d*$/.test(quantity) || quantity == ""){
        $(e.target).val(0);
      } else {
        $(e.target).val(parseInt(quantity));
      }
    });

    this.getQuantitySelector().off("change").on("change", (e)=>{
      this.sendData(e);
    });

    this.getForm().off("submit").on("submit", (e)=>{
      return false;
    });
  }

  attachQuantityClickListener(){
    this.getQuantitySelector().off("click").on("click", (e)=>{
      e.target.setSelectionRange(0, e.target.value.length);
    });
  }

  attachQuantityControlListeners(){
    this.getQuantityAdd().off("click").on("click", (e)=>{this.incrementQuantity(e)});
    this.getQuantityReduce().off("click").on("click", (e)=>{this.decrementQuantity(e)});
  }

  attachRemoveItemClickListener(){
    this.getItemRmove().off("click").on("click", (e)=>{
      this.removeItem(e);
      return false;
    })
  }

    getQuantitySelector(){
      if(this.container){
        return this.container.find(".cart-controls .quantity-selector");
      } else {
        return $(".cart-controls .quantity-selector");
      }
    }

    getQuantityAdd(){
      if(this.container){
        return this.container.find(".cart-controls .quantity-add");
      } else {
        return $(".cart-controls .quantity-add");
      }
    }

    getQuantityReduce(){
      if(this.container){
        return this.container.find(".cart-controls .quantity-reduce");
      } else {
        return $(".cart-controls .quantity-reduce");
      }
    }

    getItemRmove(){
      if(this.container){
        return this.container.find(".cart-controls-item-remove .item-remove");
      } else {
        return $(".cart-controls-item-remove .item-remove");
      }
    }

    getForm(){
      if(this.container){
        return this.container.find("form.cart-controls");
      } else {
        return $("form.cart-controls");
      }
    }

  incrementQuantity(e){
    let button = $(e.target);
    let controls = button.closest(".cart-controls");
    let quantityInput = controls.find(".quantity-selector");
    let quantity = parseInt(quantityInput.val());
    quantityInput.val(quantity + 1);
    this.sendDebouncedData(e, quantityInput, quantity);
  }

  decrementQuantity(e){
    let button = $(e.target);
    let controls = button.closest(".cart-controls");
    let quantityInput = controls.find(".quantity-selector");
    let quantity = parseInt(quantityInput.val());
    if(quantity == 0) return;
    quantityInput.val(quantity - 1);
    this.sendDebouncedData(e, quantityInput);
  }

  removeItem(e){
    this.sendData(e, 0);
  }

  sendDebouncedData(e, quantityInput, oldQuantity){
    if (this.timeoutIds[this.getProductId(e)]) {
      clearTimeout(this.timeoutIds[this.getProductId(e)]);
    }
    this.timeoutIds[this.getProductId(e)] = setTimeout(()=>{
      this.sendData(e, quantityInput, oldQuantity);
    }, 500);
  }

  sendData(e, quantityInput, oldQuantity){
    let form = $(e.target).closest("form");
    let errorMsg = "Error agregando producto. Volvé a intentar";
    axios.post(form.attr("action"), form.serialize()).then( (response) => {
      if(response.data.success){
        let productId = form.find("[name='product_id']").val();
        this.options.successCallback(response.data, productId);
        (new ToastPopper(response.data.message)).success();
      } else if(response.data.redirect){
        location.href = response.data.redirect;
        return;
      } else if(response.data.showVerifiedEmailAlert){
        quantityInput.val(0);
        this.options.showVerifiedEmailAlertCallback();
      } else {
        quantityInput.val(oldQuantity);
        (new ToastPopper((response.data.message || errorMsg))).danger();
      }
    }).catch(function (error) {
      if(error.response.status === 401){

      } else {
        (new ToastPopper(error)).danger();
      }
    });
  }

  getProductId(e){
   return $(e.target).closest("form").find("[name='product_id']").val();
  }

}


